import "./TopBar.scss";
import AccountMenu from "./AccountMenu";
import EnvironmentTitleMenu from "./EnvironmentTitleMenu";
import config from "../../../core/frontend/components/config";


export function TopBar() {
    let logoAreaClass = "logo-area";

    if (config.REACT_APP_ENABLE_ENVIRONMENT_MENU === "true") {
        logoAreaClass += " environment-menu-enabled";
    }

    return <div className={"top-bar"} data-testid={"top-bar"}>

        <div className={logoAreaClass}>
            {
                config.REACT_APP_LOGO_URL !== "" ?
                    <div className={"logo-image"}>
                        <img
                            src={config.REACT_APP_LOGO_URL}
                            alt={config.REACT_APP_TOP_BAR_TITLE}
                            className={"logo-image"}
                        />
                    </div>
                    : null
            }
            {
                config.REACT_APP_LOGO_URL === "" ?
                    config.REACT_APP_ENABLE_ENVIRONMENT_MENU === "true"
                        ? <EnvironmentTitleMenu/>
                        : <span className={"logo-text"}>
                            {config.REACT_APP_TOP_BAR_TITLE}
                          </span>
                : null
            }
        </div>

        <AccountMenu/>

    </div>
}

export default TopBar;
