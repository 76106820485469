const config = {};

/**
 * Load in the keys dynamically from the process env.
 * This ensures that the frontend compiler optimizer
 * doesn't attempt to automatically resolve any conditionals
 * that involve these environment variables.
 * **/
for (let key of Object.keys(process.env)) {
    if (key.startsWith("REACT_APP_")) {
        config[key] = process.env[key];
    }
}

if (config.REACT_APP_OVERWRITE_BACKEND_API_URL_WITH_CURRENT_ORIGIN === "true") {
    // First lets parse the current backend API url
    const currentBackendApiUrl = new URL(config.REACT_APP_BACKEND_API_URL);

    // Now lets parse the current origin
    const currentOrigin = new URL(window.location.origin);

    // Now lets overwrite the host portion of the backend API url with the current origin
    currentBackendApiUrl.host = currentOrigin.host;

    // Now we save back the modified backend API url
    config.REACT_APP_BACKEND_API_URL = currentBackendApiUrl.toString();
}

export default config;
