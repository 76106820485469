import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import "./EnvironmentTitleMenu.scss";
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import GroupsIcon from '@mui/icons-material/Groups';
import config from "../../../core/frontend/components/config";
import SchemaIcon from '@mui/icons-material/Schema';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";


export default function EnvironmentTitleMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onReceptionistClick = () => {
      window.location.href = "https://receptionist.prosperalab.ai/"
  }

  const onSalesBotClick = () => {
      window.location.href = "https://salesbot.prosperalab.ai/"
  }

  const onNetworkingBotClick = () => {
      window.location.href = "https://networkingbot.prosperalab.ai/"
  }

  const onFlowThoughtClick = () => {
      window.location.href = "https://flowthought.prosperalab.ai/"
  }

  return (
    <div className={"environment-menu"}>
      <Button
        id="environment-title-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        className={"title-button"}
        onClick={handleClick}
      >
        {config.REACT_APP_TOP_BAR_TITLE}
      </Button>
      <Menu
        id="environment-selector"
        className={"environment-selector"}
        aria-labelledby="environment-title-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={onReceptionistClick}><PhoneInTalkIcon />&nbsp;&nbsp;<LocalTranslatedText language={"en"} text="Receptionist" /></MenuItem>
        <MenuItem onClick={onSalesBotClick}><MonetizationOnIcon />&nbsp;&nbsp;<LocalTranslatedText language={"en"} text="Sales Bot"/></MenuItem>
        <MenuItem onClick={onNetworkingBotClick}><GroupsIcon />&nbsp;&nbsp;<LocalTranslatedText language={"en"} text="Networking Bot"/></MenuItem>
        <MenuItem onClick={onFlowThoughtClick}><SchemaIcon />&nbsp;&nbsp;<LocalTranslatedText language={"en"} text="FlowThought" /></MenuItem>
      </Menu>
    </div>
  );
}

