import "./LeftSideMenu.scss";
import * as React from "react";
import ArticleIcon from "@mui/icons-material/Article";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import BusinessIcon from "@mui/icons-material/Business";
import Button from "@mui/material/Button";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DescriptionIcon from "@mui/icons-material/Description";
import DifferenceIcon from "@mui/icons-material/Difference";
import DvrIcon from "@mui/icons-material/Dvr";
import EditNoteIcon from "@mui/icons-material/EditNote";
import EngineeringIcon from "@mui/icons-material/Engineering";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ListItemIcon from "@mui/material/ListItemIcon";
import MessageIcon from "@mui/icons-material/Message";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import PeopleIcon from "@mui/icons-material/People";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import PortraitIcon from "@mui/icons-material/Portrait";
import SchemaIcon from "@mui/icons-material/Schema";
import SchoolIcon from "@mui/icons-material/School";
import SellIcon from "@mui/icons-material/Sell";
import SendIcon from "@mui/icons-material/Send";
import OutboundIcon from "@mui/icons-material/Outbound";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import TopicIcon from "@mui/icons-material/Topic";
import WebIcon from "@mui/icons-material/Web";
import config from "../../../core/frontend/components/config";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useAPI, useIsAuthenticated } from "../components/APIProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { useFeatures } from "../components/FeaturesProvider";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";

export default function LeftSideMenu() {
  const { loginWithPopup } = useAuth0();
  const isAuthenticated = useIsAuthenticated();
  const enabledFeatures = useFeatures();
  const api = useAPI();

  // console.log(enabledFeatures);

  const handleBillingClick = useCallback(
    (evt) => {
      evt.preventDefault();
      api.getBookingPortalUrl().then((data) => {
        window.location.href = data.url;
      });
    },
    [api]
  );

  let menuList;
  if (!enabledFeatures) {
    // Keep the menu list as null until the list of features is loaded
    menuList = null;
  } else if (!isAuthenticated) {
    menuList = (
      <div className={"menu-chart-list-wrapper unauthenticated"}>
        <span className={"please-login-text"}>
          <LocalTranslatedText language={"en"} text='Please Login' />
        </span>
        <br />
        <Button
          color={"info"}
          className={"login-button"}
          onClick={loginWithPopup}
          variant={"outlined"}
        >
          <LocalTranslatedText language={"en"} text='Login' />
        </Button>
      </div>
    );
  } else {
    menuList = (
      <div className={"menu-pages-wrapper"} data-testid={"left-side-menu"}>
        <List>
          {config.REACT_APP_ENABLE_DASHBOARD_MENU === "true" ? (
            <ListItem disablePadding color={"primary"}>
              <NavLink
                to={`/`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText language={"en"} text='Home' />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_PERSONAL_INFO_MENU === "true" &&
          enabledFeatures?.personal_info_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/personal_info`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <SettingsAccessibilityIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Personal Info'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_CONTACTS_MENU === "true" &&
          enabledFeatures?.contact_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/contacts`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText language={"en"} text='Contacts' />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_BUSINESS_INFO_MENU === "true" &&
          enabledFeatures?.business_info_repository ? (
            <ListItem disablePadding color={"primary"}>
              <NavLink
                to={`/business_info`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Business Profile'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_VOICE_CONFIG_MENU === "true" &&
          enabledFeatures?.voice_configuration_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/voice_configuration`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <PhoneInTalkIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Voice Configuration'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_SCHEDULING_MENU === "true" &&
          enabledFeatures?.calendar_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/calendars`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <CalendarTodayIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText language={"en"} text='Calendars' />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_SCHEDULING_MENU === "true" &&
          enabledFeatures?.scheduling_rules_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/scheduling_rules`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <AccessTimeIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Scheduling Rules'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_BOOKINGS_MENU === "true" &&
          enabledFeatures?.booking_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/bookings`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <EventNoteIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText language={"en"} text='Bookings' />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_CONVERSATIONS_MENU === "true" &&
          enabledFeatures?.conversation_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/conversations`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <MessageIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Conversations'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_BILLING_MENU === "true" &&
          enabledFeatures?.billing_manager ? (
            <ListItem disablePadding>
              <NavLink
                to={`/subscription`}
                onClick={handleBillingClick}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <SellIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Billing & Subscription'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_INSURANCE_AGENTS_MENU === "true" &&
          enabledFeatures?.insurance_agent_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/insurance_agents`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <EngineeringIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Insurance Agents'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_CUSTOMERS_MENU === "true" &&
          enabledFeatures?.customer_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/customers`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText language={"en"} text='Customers' />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_TRAINING_MENU === "true" &&
          enabledFeatures?.conversation_label_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/training`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <SchoolIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText language={"en"} text='Training' />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_WEB_CHAT_MENU === "true" &&
          enabledFeatures?.web_chat_communication_channel ? (
            <ListItem disablePadding>
              <NavLink
                to={`/chat`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Chat with your Bot'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_SYNTHETIC_USER_MENU === "true" &&
          enabledFeatures?.synthetic_user_communication_channel ? (
            <ListItem disablePadding>
              <NavLink
                to={`/synthetic_user`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <PortraitIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Synthetic User'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_GENERATE_STORY_MENU === "true" &&
          enabledFeatures?.story_generator ? (
            <ListItem disablePadding>
              <NavLink
                to={`/generate_story`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <AutoStoriesIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Generate Story'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_GENERATE_STORY_MENU === "true" &&
          enabledFeatures?.story_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/story`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <DvrIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='List Stories'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_STORY_PROMPT_CONFIGURATION_MENU === "true" &&
          enabledFeatures?.story_prompt_configuration_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/story_prompt_configuration`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Prompt Configuration'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_WEB_CHAT_CONFIGURATION_MENU === "true" &&
          enabledFeatures?.web_chat_configuration_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/web_chat_configuration`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <ConnectedTvIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Web Chat Config'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_PROMPT_CHARTS_MENU === "true" &&
          enabledFeatures?.prompt_chart_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/charts`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <AccountTreeIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Prompt Charts'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_INSPECTION_CONFIGURATION_MENU === "true" &&
          enabledFeatures?.inspection_configuration_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/inspection_configuration`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <SchemaIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Inspection Configuration'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_RESUME_LIST_MENU === "true" &&
          enabledFeatures?.resume_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/resumes`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText language={"en"} text='Resumes' />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_CONTENT_SAMPLE_MENU === "true" &&
          enabledFeatures?.content_sample_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/content_samples`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <ArticleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Content Samples'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_TOPIC_EXTRACTOR_PROMPT_MENU === "true" &&
          enabledFeatures?.topic_extractor_prompt_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/topic_extractor_prompts`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <TopicIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Topic Prompts'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_TRANSCRIPT_PROCESSOR_CONFIGURATION_MENU ===
            "true" &&
          enabledFeatures?.transcript_processor_configuration_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/transcript_processor_configurations`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <EditNoteIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Processor Configs'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_PROCESS_TRANSCRIPT_MENU === "true" &&
          enabledFeatures?.transcript_processor ? (
            <ListItem disablePadding>
              <NavLink
                to={`/process_transcript`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <DifferenceIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Process New Transcript'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_GENERATED_CONTENT_MENU === "true" &&
          enabledFeatures?.generated_content_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/generated_content`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <NewspaperIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Generated Content History'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_PROPOSAL_MENU === "true" &&
          enabledFeatures?.proposal_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/proposals`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <ReceiptIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText language={"en"} text='Proposals' />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_IMPORTED_DOCUMENT_MENU === "true" &&
          enabledFeatures?.imported_document_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/imported_documents`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <ArticleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Imported Documents'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_KNOWLEDGE_MENU === "true" &&
          enabledFeatures?.knowledge_chunk_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/knowledge`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <ModelTrainingIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText language={"en"} text='Knowledge' />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_KNOWLEDGE_BASE_CONFIGURATION_MENU ===
            "true" &&
          enabledFeatures?.knowledge_base_configuration_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/knowledge_base_configuration`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <SettingsApplicationsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Knowledge Base Configuration'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_DEFAULT_OUTBOUND_RULES_MENU === "true" &&
          enabledFeatures?.default_outbound_rules_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/default_outbound_rules`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <OutboundIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text={`${config.REACT_APP_DEFAULT_OUTBOUND_RULES_WORD} Rules`}
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_DOCUMENT_TEMPLATE_MENU === "true" &&
          enabledFeatures?.document_template_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/document_template`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text={`Document Templates`}
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_IMPORTED_WEBPAGE_MENU === "true" &&
          enabledFeatures?.imported_webpage_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/imported_webpages`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <WebIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text={`Imported Webpage`}
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_GENERATED_DOCUMENT_MENU === "true" &&
          enabledFeatures?.generated_document_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/generated_document`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <ArticleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text={`Generated Document`}
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
          {config.REACT_APP_ENABLE_INTAKE_BOT_LIST_MENU === "true" &&
          enabledFeatures?.intake_survey_repository ? (
            <ListItem disablePadding>
              <NavLink
                to={`/intakes`}
                className={({ isActive, isPending }) => {
                  return isActive ? "active" : isPending ? "pending" : "";
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <LocalTranslatedText
                        language={"en"}
                        text='Intake Surveys'
                      />
                    }
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : null}
        </List>
      </div>
    );
  }

  return <div className={"left-side-menu"}>{menuList}</div>;
}
