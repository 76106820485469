import React from 'react';
import errorHandler from "./ErrorReporting";


// Use a class component to hook into life-cycle componentDidCatch
class ErrorBoundary extends React.Component {
    state = {}

    componentDidCatch(error, info) {
        if (errorHandler) {
            errorHandler.report(error);
        }

        console.error(error, info);

        localStorage.removeItem('chart-default');
        localStorage.removeItem('chart-list-state');

        if (String(error).includes("ChunkLoadError")) {
            // This means a new version of the application has been deployed.
            // Force a full page hard reload
            window.location.reload(true);
        }
    }

    render() {
        return this.props.children;
    }
}

export default ErrorBoundary;

