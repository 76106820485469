import axios from "axios";

const api = {
  async saveInspectionConfiguration(data) {
    try {
      const response = await axios.put(`/inspection_configuration`, data);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async getInspectionConfiguration() {
    try {
      const response = await axios.get(`/inspection_configuration`);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
};

export default api;
