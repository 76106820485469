import React, { useContext, useEffect, useMemo } from "react";
import { api, apiAccessTokenContext } from "./api";
import { useSnackbar } from "./SnackbarProvider";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginPage } from "../pages/LoginPage";
import ApplicationBootingPage from "../pages/ApplicationBootingPage";
import config from "../../../core/frontend/components/config";
import errorHandler from "./ErrorReporting";

const APIContext = React.createContext(null);
const UserContext = React.createContext(null);
const IsAuthenticatedContext = React.createContext(null);

const createTransformedAPI = (api, snackbar) => {
  const transformedAPI = {};
  for (let key in api) {
    transformedAPI[key + "_noToastOnError"] = api[key];

    transformedAPI[key] = async (...args) => {
      try {
        return await api[key](...args);
      } catch (err) {
        let message = `Error while making ${key} API call: ${err.message}\n${err.stack}`;
        if (err.toString().includes("Network Error")) {
          message = `Unable to connect to the server. Server may be offline`;
        } else if (err?.response && err?.response?.data) {
          if (err?.response?.data?.message) {
            message = err.response.data.message;
          } else if (err?.response?.data?.error) {
            message = err.response.data.error;
          } else {
            message = JSON.stringify(err.response.data, null, 2);
          }
        } else if (err?.response?.status === 401) {
          message = `You are not authorized to perform this action. Please log in and try again.`;
        } else if (err?.response?.status === 403) {
          message = `You are not authorized to perform this action. Please contact your administrator for assistance.`;
        } else if (err?.response?.status === 404) {
          message = `The requested resource was not found. Please refresh the page or log out and back in.`;
        }

        snackbar.toast({
          message: message,
          severity: "error",
          autoHideMs: 10000,
        });

        // Swallow the error and ignore it
        throw err;
      }
    };
  }
  return transformedAPI;
};

/**
 * The API provider is a wrapper around the API that can display error
 * messages in a UI Snackbar if any occur. This allows all components
 * to use the API while getting a basic level of feedback on errors
 * without explicitly handling those errors
 *
 * @param children
 * @returns {Element}
 * @constructor
 */
export const APIProvider = ({ children }) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  let injectedToken = null;
  // Check localStorage for an injected-token field, which is used by automated tests to
  // inject a token into the frontend so that test code can be run.
  if (window.location.search.includes("injected-token=")) {
    // Check the URL for an injected-token query parameter, which is used by automated tests to
    // inject a token into the frontend so that test code can be run.
    const url = new URL(window.location.href);
    injectedToken = url.searchParams.get("injected-token");
    axios.defaults.headers.common["WWW-Authenticate"] = injectedToken;
    // Change the query to remove injected token but keep everything else
    url.searchParams.delete("injected-token");
  }

  const [apiAccessToken, setApiAccessToken] = React.useState(injectedToken);

  const snackbar = useSnackbar();
  const transformedAPI = useMemo(
    () => createTransformedAPI(api, snackbar),
    [snackbar]
  );

  useEffect(() => {
    console.log("starting useEffect");
    const getAccessToken = async () => {
      try {
        const options = {
          authorizationParams: {
            audience: config.REACT_APP_AUTH0_AUDIENCE,
          },
        };

        console.log("getAccessTokenSilently");
        const accessToken = await getAccessTokenSilently(options);
        console.log("accessToken");

        setApiAccessToken(accessToken);
        axios.defaults.headers.common["WWW-Authenticate"] = accessToken;
        if (errorHandler) {
          errorHandler.setUser(user?.email);
        }
      } catch (e) {
        console.log("Error during login", e);
        snackbar.toast({
          message: e.toString(),
          severity: "error",
          autoHideMs: 5000,
        });
      }
    };

    if (!apiAccessToken && injectedToken) {
      axios.defaults.headers.common["WWW-Authenticate"] = injectedToken;
      setApiAccessToken(injectedToken);
    } else if (isAuthenticated && !apiAccessToken) {
      getAccessToken().then(() => {
        let redirectPath = "";
        if (localStorage.getItem("prospera-login-redirect-path")) {
          redirectPath = localStorage.getItem("prospera-login-redirect-path");
          localStorage.removeItem("prospera-login-redirect-path");
        }
        if (redirectPath && window.location.pathname !== redirectPath) {
          window.location.pathname = redirectPath;
        }
      });
    }
  }, [
    isAuthenticated,
    getAccessTokenSilently,
    user?.sub,
    user?.email,
    apiAccessToken,
    snackbar,
    injectedToken,
  ]);

  if (config.REACT_APP_REQUIRE_LOGIN === "true") {
    if (injectedToken) {
        if (!apiAccessToken) {
          return <ApplicationBootingPage/>;
        }
    } else if (!isAuthenticated) {
      return <LoginPage />;
    } else if (isAuthenticated && !apiAccessToken) {
      return <ApplicationBootingPage />;
    }
  }

  return (
    <apiAccessTokenContext.Provider value={apiAccessToken || injectedToken}>
      <APIContext.Provider value={transformedAPI}>
        <IsAuthenticatedContext.Provider value={injectedToken ? true : isAuthenticated}>
            <UserContext.Provider value={injectedToken ? {} : user}>
              {children}
            </UserContext.Provider>
        </IsAuthenticatedContext.Provider>
      </APIContext.Provider>
    </apiAccessTokenContext.Provider>
  );
};

export const useUser = useContext.bind(null, UserContext);
export const useIsAuthenticated = useContext.bind(null, IsAuthenticatedContext);
export const useAPI = useContext.bind(null, APIContext);
